<template>
    <v-col cols="12" md="6" lg="4" class="mb-2">
        <v-card v-if="this.visit" class="card" @click="goToDetailPage()">
            <DescriptionItem
                icon="edt-info"
                :text="[visit.name]"
            />
            <DescriptionItem
                icon="edt-clock"
                :text="[dateIntervale(visit.date_begin.date, visit.date_end.date)]"
            />
            <AlertExclamation v-if="visit.late" />
        </v-card>
    </v-col>
</template>

<script>
    import moment from "moment";
    import DescriptionItem from "@/components/detail/DescriptionItem";
    import AlertExclamation from "@/components/detail/AlertExclamation";
    export default {
        name: 'VisitList',
        props: {
            visit: {
                type: Object,
                required: true
            }
        },
        components: { DescriptionItem, AlertExclamation },
        methods: {
            dateIntervale: function (date1, date2) {
                return 'Du <b>' + moment(String(date1)).format('DD/MM/YYYY HH:mm') + '</b> au <b>' + moment(String(date2)).format('DD/MM/YYYY HH:mm') + '</b>';
            },
            goToDetailPage() {
                this.$router.push({
                  name: "VisitDetail",
                  params: {
                    id: this.visit.id,
                  },
                });
            }
        },
    };
</script>
