import axios from 'axios'
import Vue from 'vue'

const state = {
    visits: null,
    visit: null,
    visitInfos: null,
    sectionsIndex: null,
    mine: true,
    currentFormId: null,
    currentSectionsId: null,
    currentStartedVisit: null,
    seeHidden: false,
    modeEditionVisit: false,
}

const getters = {
    StateVisits: state => state.visits,
    StateVisit: state => state.visit,
    StateVisitInfos: state => state.visitInfos,
    StateSectionsIndex: state => state.sectionsIndex,
    StateMine: state => state.mine,
    StateCurrentFormId: state => state.currentFormId,
    StateCurrentSectionsId: state => state.currentSectionsId,
    StateCurrentStartedVisit: state => state.currentStartedVisit,
    StateSeeHidden: state => state.seeHidden,
    StateModeEditionVisit: state => state.modeEditionVisit,
}

const actions = {
    async GetVisits({ commit, rootState }) { /* Liste des visites */
        let token = rootState.auth.userToken;
        let mine = rootState.visitStore.mine;
        let url = '/api/visits?assignedToUser=' + mine;

        return await axios.get(url, { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
          .then(function (res) {
              commit("StateVisits", res.data)
          })
          .catch(function (error) {
              Vue.prototype.handleError(error);
          })
    },
    async GetVisitForms({ commit, rootState }, id) { /* Forms json d'une visite */
        if (localStorage.getItem('visit-' + id) === null || localStorage.getItem('index-visit-' + id) === null) {
            let token = rootState.auth.userToken;
            return await axios.get('/api/visit/' + id,
                { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
                    .then(function (res) {
                        commit("StateCurrentFormId", null);
                        commit("StateCurrentSectionsId", null);
                        commit("StateVisit", res.data[0]);
                        commit("StateSectionsIndex", res.data[1]);
                        /*return res.data[0];*/
                    })
                .catch(function (error) {
                    Vue.prototype.handleError(error);
                })
        }else {
            let visitForms = JSON.parse(localStorage.getItem('visit-'+id));
            let indexVisitForms = JSON.parse(localStorage.getItem('index-visit-'+id));
            commit("StateCurrentFormId", null);
            commit("StateCurrentSectionsId", null);
            commit("StateVisit", visitForms);
            commit("StateSectionsIndex", indexVisitForms);
            /*return visitForms;*/
        }
    },
    async GetVisitInfos({ commit, rootState }, id) { /* Infos de la visite */
        if (localStorage.getItem('infos-visit-' + id) === null) {
            //let objetJSONStocke = JSON.parse(localStorage.getItem('visit-'+id));
            let token = rootState.auth.userToken;
            return await axios.get('/api/visit/informations/' + id,
                { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
                .then(function (res) {
                    commit("StateVisitInfos", res.data);
                    return res.data;
                })
                .catch(function (error) {
                    Vue.prototype.handleError(error);
                })
        }else {
            let visitInfos = JSON.parse(localStorage.getItem('infos-visit-'+id));
            commit("StateVisitInfos", visitInfos);
            return visitInfos;
        }
    },
    SwitchSeeHidden({ commit }, state) {
        commit("StateSeeHidden", state);
    },
    SwitchModeEdition({ commit }, state) {
        commit("StateModeEditionVisit", state);
    },
    SwitchVisitsList({ commit }, state) {
        commit("StateMine", state);
    },
    SetCurrentFormId({ commit }, formId) {
        commit("StateCurrentFormId", formId);
        commit("StateCurrentSectionsId", null);
    },
    SetCurrentSectionsId({ commit }, data) {
        commit("StateCurrentFormId", data[0]);
        commit("StateCurrentSectionsId", data[1]);
    },
    UpdateJson({ commit }, data) {
        localStorage.setItem('visit-' + data[0], JSON.stringify(data[1]));
        commit("StateVisit", data[1]);
    },
    UpdateIndex({ commit }, data) {
        let newIndex = JSON.parse(localStorage.getItem('index-visit-' + data[0]));
        Vue.set(newIndex, data[1], data[2]);
        localStorage.setItem('index-visit-' + data[0], JSON.stringify(newIndex));
        commit("StateSectionsIndex", newIndex);
    },
    async StartVisit({ commit, rootState }, id) {
        let token = rootState.auth.userToken;
        return await axios.post('/api/visit/' + id + '/start',
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function () {
                localStorage.setItem('visit-'+id, JSON.stringify(rootState.visitStore.visit));
                localStorage.setItem('index-visit-' + id, JSON.stringify(rootState.visitStore.sectionsIndex));
                localStorage.setItem('infos-visit-' + id, JSON.stringify(rootState.visitStore.visitInfos));

                commit("StateCurrentStartedVisit", id);
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    async SendVisit({ commit, rootState }, data) {
        let token = rootState.auth.userToken;
        let visitJson = JSON.parse( localStorage.getItem('visit-' + data[0]));
        let visitId = data[0];

        return await axios.post('/api/visit/' + visitId + '/' + data[1], {'visitData': visitJson},
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function () {
                commit("StateCurrentStartedVisit", null);
                localStorage.removeItem('visit-' + visitId);
                localStorage.removeItem('index-visit-' + visitId);
                localStorage.removeItem('infos-visit-' + visitId);
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
    async AssignVisit({ rootState }, data) {
        let token = rootState.auth.userToken;
        return await axios.put('/api/visit/assign/' + data[0],
            { headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + token } })
            .then(function () {
                localStorage.removeItem('infos-visit-' + data[0]);
                this.GetVisitInfos(data[0]);
            })
            .catch(function (error) {
                Vue.prototype.handleError(error);
            })
    },
}

const mutations = {
    StateVisits(state, visits) {
        state.visits = visits
    },
    StateVisit(state, visit) {
        state.visit = visit
    },
    StateVisitInfos(state, visitInfos) {
        state.visitInfos = visitInfos
    },
    StateSectionsIndex(state, sectionsIndex) {
        state.sectionsIndex = sectionsIndex
    },
    StateMine(state, mine) {
        state.mine = mine
    },
    StateCurrentFormId(state, currentFormId) {
        state.currentFormId = currentFormId
    },
    StateCurrentSectionsId(state, currentSectionsId) {
        state.currentSectionsId = currentSectionsId
    },
    StateCurrentStartedVisit(state, currentStartedVisitId) {
        state.currentStartedVisit = currentStartedVisitId
    },
    StateSeeHidden(state, seeHidden) {
        state.seeHidden = seeHidden
    },
    StateModeEditionVisit(state, modeEditionVisit) {
        state.modeEditionVisit = modeEditionVisit
    },
    LogOut(state) {
        state.visits = null;
        state.visit = null;
    }
}
export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}