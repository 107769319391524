<template>
    <v-file-input
            :label="label"
            @change="updateValue"
            outlined
    ></v-file-input>
</template>

<script>
    export default {
        name: 'File',
        props: {
            label: {
                required: true
            }
        },
        methods: {
            updateValue() {
            }
        },
    };
</script>
