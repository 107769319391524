<template>
    <div class="stepper-wrap-content page-wrapper page-wrapper-new justify-content-normal">
        <v-text-field @keyup="searchDatas" v-model="searchRealEstate" :label="$t('serve.new.searchRealEstate')" class="pt-2 flex-grow-0">
            <template v-slot:append>
                <v-icon color="red" @click="cleanSearch">
                    mdi-close
                </v-icon>
            </template>
        </v-text-field>

        <div class="mt-3 page-content">
            <v-row class="flex-grow-0">
                <v-col cols="12" md="4" sm="6" class="mb-2 mb-md-5" v-for="(element, key) in realEstatesSelectedFiltered" :key="key">
                    <v-card class="d-flex card box-shadow vh-100p lh-30"
                            @click="selectRealEstate(element.id)"
                    >
                            <span class="btn-round btn-disabled mr-3 flex-shrink-0">
                                <i class="edt-info"></i>
                            </span>
                        <div>{{ element.name }}</div>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
    export default {
        name: "RealEstateStep",
        props: ['realEstates'],
        data() {
            return {
                realEstatesSelected: [],
                searchRealEstate: '',
                realEstatesSelectedFiltered: []
            };
        },
        methods: {
            selectRealEstate(elementId) {
                var subLevel = this.realEstates.realEstatesSubLevel[elementId]
                if (subLevel) {
                    this.realEstatesSelectedFiltered = subLevel
                    this.realEstatesSelected = subLevel
                    this.searchRealEstate = '';
                } else {
                    this.submitForm(elementId)
                }
            },
            submitForm(key) {
                this.$emit("updatevalue", "realEstate", key);
                this.$emit("incrementstep");
            },
            searchDatas() {
                var realEstateTmp = [];
                Object.keys(this.realEstatesSelected).forEach((key) => {
                    if (this.realEstatesSelected[key].name.toLowerCase().includes(this.searchRealEstate.toLowerCase())) {
                        realEstateTmp.push(this.realEstatesSelected[key])
                    }
                })
                this.realEstatesSelectedFiltered = realEstateTmp;
            },
            cleanSearch() {
                this.searchRealEstate = ''
                this.realEstatesSelectedFiltered = this.realEstatesSelected;
            }
        },
        created() {
            var lowerValue = this.realEstates.realEstates.length;
            for (var i = 0; i <= this.realEstates.realEstates.length; i++) {
                if (this.realEstates.realEstates[i] && i < lowerValue) {
                    lowerValue = i;
                }
            }
            this.realEstatesSelected = this.realEstates.realEstates[lowerValue];
            this.realEstatesSelectedFiltered = this.realEstates.realEstates[lowerValue];
        }
    };
</script>