<template>
        <div class="justify-content-between">
            <v-btn v-if="this.previous != null">{{ $t('previous')}}</v-btn>
            <v-btn v-if="this.next != null">{{ $t('next')}}</v-btn>
        </div>
</template>

<script>
    import { mapActions } from "vuex";
    export default {
        name: 'PrevNext',
        components: {  },
        data() {
            return {
                previous: null,
                next: null
            };
        },
        methods: {
            ...mapActions([]),
            majNav() {

            },
        },
        created() {
            this.majNav();
        }
    };
</script>
