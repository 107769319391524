<template>
    <v-col cols="12" md="6" lg="4" class="mb-2">
        <v-card class="card" @click="setCurrentFormId(form.form_id)">
            <DescriptionItem
                icon="edt-info"
                :text="[form.form_name]"
                additionalClass="mb-0"
            />
        </v-card>
    </v-col>
</template>

<script>
    import { GlobalEventEmitter } from '@/plugins/GlobalEventEmitter';
    import { mapActions } from "vuex";
    import DescriptionItem from "@/components/detail/DescriptionItem";
    export default {
        name: 'FormItem',
        props: {
            form: {
                type: Object,
                required: true
            }
        },
        components: { DescriptionItem },
        methods: {
            ...mapActions('visitStore', ["SetCurrentFormId"]),
            setCurrentFormId(formId) {
                this.SetCurrentFormId(formId);
                GlobalEventEmitter.$emit('visitNavigation');
            },
        }
    };
</script>
