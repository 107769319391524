<template>
    <div v-if="visitInfos" class="text-center">
        <span v-if="!isStarted() && isMine()" class="btn-round-big bg-green mx-2" @click="startVisit()"><v-icon>mdi-play</v-icon></span>
        <span v-if="isStarted()" class="btn-round-big btn-primary mx-2" @click="sendVisit('draft')"><v-icon>mdi-pause</v-icon></span>
        <span v-if="isStarted()" class="btn-round-big bg-alert mx-2" @click="sendVisit('terminate')"><v-icon>mdi-stop</v-icon></span>
        <span v-if="!isMine()" class="btn-round-big bg-blue mx-2" @click="assignVisit()"><v-icon>mdi-account-arrow-left-outline</v-icon></span>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from "vuex";
    export default {
        name: 'VisitActions',
        computed: {
            ...mapGetters( 'visitStore', {currentStartedVisit: 'StateCurrentStartedVisit', visitInfos: 'StateVisitInfos'} ),
        },
        methods: {
            ...mapActions('visitStore',["StartVisit", "SendVisit", "AssignVisit", "GetVisitForms"]),
            /* Les éléments relatifs à la visite sont enregistrés dans le localStorage, le status de la visit passe à "start" en bdd */
            startVisit() {
                this.StartVisit(this.$route.params.id);
            },
            /* Todo : Le json est renvoyé à Sf et alimente les tables. Le status de la visite est MAj (pause/draft, stop/finish)*/
            sendVisit(state) {
                this.SendVisit([this.$route.params.id, state]).then(() => {
                    this.GetVisitForms(this.$route.params.id).then((res) => {
                        this.visit = res;
                    });
                });
            },
            /* L'utilisateur s'assigne la visite */
            assignVisit() {
                this.AssignVisit([this.$route.params.id]);
            },
            isStarted() {
                if(parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)) {
                    return true;
                }
                return false;
            },
            isMine() {
                if(parseInt(this.$store.getters.StateUserData.id) === parseInt(this.visitInfos.visit_assigned_to) && this.visitInfos.visit_status != "start") {
                    return true;
                }
                return false;
            }
        },
        created() {
        }
    };
</script>
