<template>
    <div>
        <div class="mb-2 swipe-container" :class="checkVisibility()">
            <div class="swipe-action swipe-left swipe">
                <i class="edt-eye"></i>
            </div>

            <div class="swipe-element"
                v-touch="{
                    left: () => hideItem(),
                    right: () => showItem()
                }">
                <v-text-field v-if="field.field_type == 'text' || field.field_type == 'email' || field.field_type == 'number' || field.field_type == 'date' || field.field_type == 'tel' || field.field_type == 'url'"
                    :type="field.field_type"
                    :label="field.field_name"
                    outlined
                    :placeholder="getConfig('placeholder')"
                    :required="getConfig('required', false)"
                    @change="updateField()"
                    v-model="theInputSimple"
                    :disabled="isDisabled()"
                ></v-text-field>
                <v-textarea v-if="field.field_type == 'textarea'"
                    :label="field.field_name"
                    outlined
                    :placeholder="getConfig('placeholder')"
                    :required="getConfig('required', false)"
                    v-model="theInputSimple"
                    @change="updateField()"
                    :disabled="isDisabled()"
                ></v-textarea>

                <v-select
                    v-if="field.field_type == 'select'"
                    :items="getChoices()"
                    item-text="name"
                    item-value="val"
                    return-object
                    :label="field.field_name"
                    v-model="theInputSimple"
                    :placeholder="getConfig('placeholder')"
                    :required="getConfig('required', false)"
                    outlined
                    attach
                    @change="updateFieldChoice()"
                    :disabled="isDisabled()"
                ></v-select>
                <div v-if="field.field_type == 'radio' && Object.keys(field.field_choices).length > 0">
                    <div class="mb-2">{{ field.field_name }}</div>
                    <v-radio-group
                        @change="updateFieldChoice()"
                        :required="getConfig('required', false)"
                        v-model="theInputSimple"
                        :disabled="isDisabled()"
                    >
                        <v-radio v-for="choice in getChoices()" :key="choice.val"
                            :value="{val: choice.val, name: choice.name}"
                            :name="getInputName(choice.val)"
                            :label="choice.name"
                        ></v-radio>
                    </v-radio-group>
                </div>
                <div v-if="field.field_type == 'checkbox' && Object.keys(field.field_choices).length > 0">
                    <div class="mb-2">{{ field.field_name }}</div>
                    <v-checkbox v-for="choice in getChoices()" :key="choice.val"
                        :value="{val: choice.val, name: choice.name}"
                        :name="getInputName(choice.val)"
                        :label="choice.name"
                        v-model="theInputMultiple"
                        @change="updateFieldMultiple()"
                        :disabled="isDisabled()"
                    ></v-checkbox>
                </div>

                <v-switch v-if="field.field_type == 'boolean'"
                          v-model="theInputSimple"
                          :label="field.field_name"
                          hide-details
                          @change="updateField()"
                          :disabled="isDisabled()"
                ></v-switch>
                <div v-if="field && field.field_type == 'file' || field.field_type == 'image'">
                    <File :label="field.field_name" />
                </div>
            </div>
            <div class="swipe-action swipe-right swipe">
                <i class="edt-eye-slash"></i>
            </div>
        </div>
        <v-row>
            <v-col cols="12">
                <Comment :comment="this.fieldComment" @updateCommentField="updateCommentField" type="field" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapGetters } from "vuex";
    import File from '@/components/forms/File.vue';
    import Comment from '@/components/visit/detail/Comment.vue';
    export default {
        name: 'FieldItem',
        props: {
            field: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters( 'visitStore', {currentStartedVisit: 'StateCurrentStartedVisit', modeEditionVisit: 'StateModeEditionVisit', seeHidden: 'StateSeeHidden'} ),
        },
        components: { /*Radio,*/ File, /*Select, Checkbox*/ Comment },
        data () {
            return {
                theInputSimple: '',
                theCheckbox: [],
                theInputMultiple: [],
                disabled: '',
                fieldComment: {
                    addComment: false,
                    comment: ''
                }
            }
        },
        methods: {
            getInputId(Id) {
                return this.field.field_type + '-item-' + Id;
            },
            getInputName(Id) {
                return this.field.field_type + Id;
            },
            addFile() {
            },
            isDisabled() {
                if(parseInt(this.currentStartedVisit) === parseInt(this.$route.params.id)) {
                    return false;
                }
                return true;
            },
            getValue() {
                if(this.field.field_type == 'checkbox') {
                    if(this.field.field_value_id != "" && JSON.parse(this.field.field_value_id).length > 0) {
                        for(let i = 0; i < JSON.parse(this.field.field_value_id).length; i++) {
                            this.theInputMultiple.push({val: JSON.parse(this.field.field_value_id)[i], name: JSON.parse(this.field.field_value)[i]})
                        }
                    }
                } else if (this.field.field_type == 'radio' || this.field.field_type == 'select') {
                    if(this.field.field_value_id != "" && JSON.parse(this.field.field_value_id).length > 0) {
                        this.theInputSimple = {val: JSON.parse(this.field.field_value_id)[0], name: JSON.parse(this.field.field_value)[0]};
                    }
                } else {
                    if (this.field.field_value != "" && JSON.parse(this.field.field_value).length > 0) {
                        this.theInputSimple = JSON.parse(this.field.field_value)[0];
                    }
                }
            },
            getChoices() {
                let choices = [];
                let fieldChoices = this.field.field_choices;
                for (const [key, value] of Object.entries(fieldChoices)) {
                     choices.push({'val' : key, 'name' : value.field_choice_name});
                }
                return choices;
            },
            getConfig(conf, defaultValue = '') {
                let confValue = defaultValue;
                let fieldConfig = this.field.field_config;
                if(fieldConfig != null && fieldConfig != '' && Object.keys(JSON.parse(fieldConfig)).length > 0) {
                    if(conf in JSON.parse(fieldConfig)) {
                        confValue = JSON.parse(fieldConfig)[conf];
                    }
                }
                return confValue;
            },
            getVModel() {
                return 'input-' + this.field.id;
            },
            updateField() {
                this.$emit("updateJson", this.field.id, [this.theInputSimple]);
            },
            updateFieldChoice() {
                this.$emit("updateJson", this.field.id, [this.theInputSimple.name], [this.theInputSimple.val]);
            },
            updateFieldMultiple() {
                if(this.theInputMultiple != null) {
                    this.theInputMultiple = this.theInputMultiple.filter(function (val) {
                        return val !== '' && val != null
                    });
                }
                let multipleId = [];
                let multipleName = [];

                this.theInputMultiple.forEach((answer) => {
                    multipleId.push(answer.val);
                    multipleName.push(answer.name);
                });
                /* todo : un select peut être multiple */
                this.$emit("updateJson", this.field.id, multipleName, multipleId);
            },
            checkVisibility() {
                let tmpClass = '';
                if (this.field.is_hidden === true)
                {
                    tmpClass = this.seeHidden === false ? 'd-none' : 'item-disabled';
                }
                return tmpClass;
            },
            hideItem() {
                if(this.modeEditionVisit === true && this.field.is_hidden !== true) {
                    this.$emit('visibilityField', { fieldId: this.field.id, hidden: true});
                }
            },
            showItem() {
                if(this.modeEditionVisit === true && this.field.is_hidden !== false) {
                    this.$emit('visibilityField', { fieldId: this.field.id, hidden: false});
                }
            },
            updateCommentField(itemComment) {
                this.fieldComment = itemComment;
                this.$emit("updateCommentField", { fieldId: this.field.id, comment: this.fieldComment});
            },
        },
        created() {
                this.getValue();
                this.fieldComment.addComment = this.field.add_comment;
                this.fieldComment.comment = this.field.comment;
        }
    };
</script>
