import VisitList from '@/views/visit/List'
import VisitDetail from '@/views/visit/Detail'
import DashboardVisit from '@/views/dashboard/DashboardVisit'
import i18n from '@/i18n'

export default [
  {
    path: '/visites',
    name: 'VisitList',
    component: VisitList,
    meta: {
      hideTopBar: true,
      hideNavBar: true,
      showNavBarVisit: true,
      title: i18n.t("visits.title"),
      requiresAuth: true
    },
  },
  {
    path: '/visite-detail:id',
    name: 'VisitDetail',
    component: VisitDetail,
    props: true,
    meta: {
      hideTopBar: true,
      hideNavBar: true,
      showNavBarVisit: true,
      title: i18n.t("visits.detail.title"),
      requiresAuth: true
    },
  },
  {
    path: '/dashboard-visites',
    name: 'DashboardVisit',
    component: DashboardVisit,
    meta: {
      title: 'DashboardVisit',
      requiresAuth: true,
      hideTopBar: true,
      hideNavBar: true,
      showNavBarVisit: true,
      containerClass: 'dashboard-visit'
    }
  }
];
